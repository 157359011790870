import Button from 'shared/src/uikit/Button';
import Icon from 'shared/src/uikit/Icon';

import styles from './PresenterNotesPopup.module.css';

interface PresenterNotesPopupProps {
  handleOpenNotes: VoidFunction;
  handleCloseNotes: VoidFunction;
}

const PresenterNotesPopup = ({
  handleOpenNotes,
  handleCloseNotes,
}: PresenterNotesPopupProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>Presenter Note</div>
      <div className={styles.content}>
        There is a presenter note on this demo. Open it in a separate tab, that
        only you can see.
      </div>
      <div className={styles.buttonWrapper}>
        <Button onClick={handleCloseNotes} variant="outline">
          NOT NOW
        </Button>
        <Button onClick={handleOpenNotes} variant="grey">
          Open Notes
        </Button>
      </div>
      <div className={styles.visibleIconWrapper}>
        <Icon className={styles.eyeIcon} name="eye2" width={15} height={15} />
        <span className={styles.visibleText}>Only visible to you</span>
      </div>
    </div>
  );
};

export default PresenterNotesPopup;
