import { VFC } from 'react';
import Icon from 'shared/src/uikit/Icon';
import Tooltip from 'shared/src/components/Tooltip';

import styles from './ShareStorylaneDefaultLogo.module.css';

interface ShareStorylaneDefaultLogoProps {
  disabledAuthenticatedTooltip: boolean;
}

const ShareStorylaneDefaultLogo: VFC<ShareStorylaneDefaultLogoProps> = (
  props
) => {
  const { disabledAuthenticatedTooltip } = props;
  return (
    <Tooltip
      disabled={disabledAuthenticatedTooltip}
      placement="top"
      triggerOn="hover"
      offsetY={10}
    >
      <Tooltip.Trigger>
        <Icon name="logo-horizontal" className={styles.logo} />
      </Tooltip.Trigger>
      <Tooltip.Content variant="dark">
        <span>Click to add your organization logo</span>
      </Tooltip.Content>
    </Tooltip>
  );
};

export default ShareStorylaneDefaultLogo;
