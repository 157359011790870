import { useEffect } from 'react';

export function useSharePageTopWindowConsoleError() {
  useEffect(() => {
    const sharePageWindowIsNotTopWindow = window.top !== window.self;
    if (sharePageWindowIsNotTopWindow) {
      const errorMessage = `Avoid using ${window.location.href} inside the iframe. Correct demo url can be copied from the editor page. Visit: ${process.env.NEXT_PUBLIC_DASHBOARD_URL}`;
      console.error(errorMessage);
    }
  }, []);
}

export function useDebugDemoCrossOriginMessages() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      const crossOriginMessageListener = (event: MessageEvent) => {
        // eslint-disable-next-line no-console
        console.debug(event);
      };
      window.addEventListener('message', crossOriginMessageListener);
      return () =>
        window.removeEventListener('message', crossOriginMessageListener);
    }
  }, []);
}
