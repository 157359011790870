import { DisplayConfigType } from 'shared/src/features/project/project.types';

/**
 * @see {@link https://storylane.atlassian.net/wiki/x/AYD4Ag Docs}
 */
export function isHtmlScaleEnabled(arg: {
  displayConfigRendererDimensions: DisplayConfigType['renderer_dimensions'];
  startPageDimensions: {
    width: number;
    height: number;
  };
  queryFlagScale: boolean | null;
  projectFlagScale: boolean;
}) {
  const {
    displayConfigRendererDimensions,
    startPageDimensions,
    queryFlagScale,
    projectFlagScale,
  } = arg;
  const flag = queryFlagScale === null ? projectFlagScale : queryFlagScale;

  if (!flag) return false;

  const finalDimensions =
    displayConfigRendererDimensions || startPageDimensions;
  const isValid = isValidDimensions(finalDimensions);
  if (!isValid) {
    console.warn('Fallback embed dimensions with default values.');
  }
  return isValid;
}

function isValidDimensions(dimensions: {
  width: number;
  height: number;
}): boolean {
  return dimensions.width > 0 && dimensions.height > 0;
}
