import { ReactNode, VFC } from 'react';
import Icon from 'shared/src/uikit/Icon';
import Tooltip from 'shared/src/components/Tooltip';

import styles from './ShareWhitelabel.module.css';

interface ShareWhitelabelLinkProps {
  text: string;
  href: string;
  tooltipContent: ReactNode;
}

const ShareWhitelabelLink: VFC<ShareWhitelabelLinkProps> = (props) => {
  const { text, tooltipContent, href } = props;
  return (
    <Tooltip disabled={!tooltipContent} triggerOn="hover">
      <Tooltip.Trigger>
        <a
          href={href}
          target="_blank"
          rel="noreferrer noopener"
          className={styles.headerLink}
        >
          <Icon name="expand-open" width={14} height={14} />
          <span>{text}</span>
        </a>
      </Tooltip.Trigger>
      <Tooltip.Content variant="dark">{tooltipContent}</Tooltip.Content>
    </Tooltip>
  );
};

export default ShareWhitelabelLink;
