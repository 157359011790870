/* eslint-disable @next/next/no-page-custom-font */
import { useEffect } from 'react';
import Head from 'next/head';
import { ProjectKind } from 'shared/src/features/project/project.constants';
import { useRouter } from 'next/router';
import { processDisplayConfig } from 'shared/src/features/project/project.util';

import SharePageImage from './SharePageImage';
import DemoNotFound from '../PublicDemoPage/DemoNotFound';
import SharePageHtml from './SharePageHtml';
import { updateQueryStringParameter } from './SharePage.utils';
import { GetPublishedProjectInfoResponse } from './api/projectInfo.api';
import HeadCustomFont from 'components/HeadCustomFont';
import {
  useDebugDemoCrossOriginMessages,
  useSharePageTopWindowConsoleError,
} from './SharePage.hooks';
import {
  CrossOriginSessionEventEvent,
  CrossOriginSessionEventMessage,
} from 'components/DemoPageCrossOrigin/DemoPageCrossOrigin.constants';

interface SharePageProps {
  project: GetPublishedProjectInfoResponse['project'] | null;
}

function SharePage({ project }: SharePageProps): JSX.Element | null {
  const { query } = useRouter();
  const projectId = query.id as string;

  const displayConfig = project && processDisplayConfig(project.display_config);

  let shouldUseMobileViewport = false;

  if (project?.kind === ProjectKind.Image) {
    shouldUseMobileViewport = true;
  } else {
    shouldUseMobileViewport = displayConfig
      ? displayConfig.mobile_viewport
      : false;
  }

  useSharePageTopWindowConsoleError();

  useDebugDemoCrossOriginMessages();

  useEffect(() => {
    const crossOriginMessageListener = (event: MessageEvent) => {
      /**
       * @see {@link https://storylane.atlassian.net/l/cp/WLmaeMRj External url issue docs}
       * @see {@link https://storylane.atlassian.net/l/cp/9SqiFyGV  Docs}
       */
      if (
        event.data.message === CrossOriginSessionEventMessage &&
        event.data.payload?.event ===
          CrossOriginSessionEventEvent.OpenedExternalUrl &&
        event.data.payload?.target.target === '_self'
      ) {
        window.open(
          event.data.payload.target.url,
          event.data.payload.target.target
        );
      }

      if (
        event.data.message === 'storylane-page-change-event' &&
        event.data.payload.pageId
      ) {
        updateQueryStringParameter('page_id', event.data.payload.pageId);
      }
    };
    window.addEventListener('message', crossOriginMessageListener);
    return () =>
      window.removeEventListener('message', crossOriginMessageListener);
  }, []);

  if (!project) {
    return <DemoNotFound testId="share-not-found" subject="link" />;
  }

  const title = displayConfig?.renderer_title || project.name;

  return (
    <>
      <Head>
        <title>{title}</title>
        {shouldUseMobileViewport ? (
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        ) : (
          <meta name="viewport" content="maximum-scale=1.0" />
        )}
      </Head>
      <HeadCustomFont font={project.display_config.font} />
      {project.kind === ProjectKind.Html && (
        <SharePageHtml projectInfo={project} projectId={projectId} />
      )}
      {project.kind === ProjectKind.Image && (
        <SharePageImage
          projectId={projectId}
          projectInfo={project}
          whitelabelSettings={project.whitelabel}
        />
      )}
    </>
  );
}

export default SharePage;
